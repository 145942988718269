import merge from "lodash-es/merge"
import type { PaginateResult } from "mongoose"

export interface PriceReference {
  componentGroup: { _id: string; description: string }
  component: { _id: string; description: string }
  price?: number
  priceYear?: number
}

export interface PropertyValueReference<T = unknown> {
  componentGroup: { _id: string; description: string }
  component: { _id: string; description: string }
  property?: { _id: string; description: string }
  value?: T
}

export interface ConditionReference {
  componentGroup: { _id: string; description: string }
  component: { _id: string; description: string }
  condition: AssetComponentCondition
}

export interface OrganizationDataQuality {
  organization: string
  assetCount: number
  general: {
    hasMainImage: number
    hasCoordinates: number
    hasAddress: number
    hasBuildYear: number

    totalCount: number
    missingCount: number
    assetsCompleted: number
    qualityPercentage: number
  }
  component: {
    totalCount: number
    missingCount: number
    assetsCompleted: number
    qualityPercentage: number
  }
  risk: {
    hasRiskProfileCount: number
    missingConditionsCount: number
    invalidConditionsCount: number
    missingBuildYearsCount: number
    totalConditionsCount: number
    totalBuildYearsCount: number

    totalCount: number
    missingCount: number
    assetsCompleted: number
    qualityPercentage: number
  }
  finance: {
    missingConditionsCount: number
    invalidConditionsCount: number
    missingBuildYearsCount: number
    missingPricesCount: number
    invalidPricesCount: number
    totalConditionsCount: number
    totalBuildYearsCount: number
    totalPricesCount: number

    totalCount: number
    missingCount: number
    assetsCompleted: number
    qualityPercentage: number
  }
}

export interface AssetDataQualityGeneralData {
  hasMainImage: boolean
  hasCoordinates: boolean
  hasAddress: boolean
  hasBuildYear: boolean

  totalCount: number
  missingCount: number
  qualityPercentage: number
}

export interface AssetDataQualityComponentData {
  missingValues: Array<PropertyValueReference>

  totalCount: number
  missingCount: number
  qualityPercentage: number
}

export interface AssetDataQualityRiskData {
  hasRiskProfile: boolean
  missingBuildYears: Array<PropertyValueReference<number>>
  missingConditions: Array<ConditionReference>
  invalidConditions: Array<ConditionReference>

  totalConditionsCount: number
  totalBuildYearsCount: number
  totalCount: number
  missingCount: number
  qualityPercentage: number
}

export interface AssetDataQualityFinanceData {
  missingBuildYears: Array<PropertyValueReference<number>>
  missingConditions: Array<ConditionReference>
  invalidConditions: Array<ConditionReference>
  missingPrices: Array<PriceReference>
  invalidPrices: Array<PriceReference>

  totalConditionsCount: number
  totalBuildYearsCount: number
  totalPricesCount: number
  totalCount: number
  missingCount: number
  qualityPercentage: number
}

export interface SimpleDataQuality {
  generalQualityPercentage: number
  componentQualityPercentage: number
  riskQualityPercentage: number
  financeQualityPercentage: number
}

export interface AssetDataQuality {
  _id: string
  asset: string
  organization: string
  generalData: AssetDataQualityGeneralData
  componentData: AssetDataQualityComponentData
  riskData: AssetDataQualityRiskData
  financeData: AssetDataQualityFinanceData
}

export const useAssetDataQualityStore = defineStore(`asset-data-quality`, () => {
  const getAssetDataQualityByPage = async (queryParameters: Record<string, any> = {}): Promise<PaginateResult<AssetDataQuality>> => {
    const defaultFilter = {
      select: {
        "generalData.hasMainImage": 1,
        "generalData.hasCoordinates": 1,
        "generalData.hasAddress": 1,
        "generalData.hasBuildYear": 1,
        "generalData.qualityPercentage": 1,
        "generalData.totalCount": 1,
        "generalData.missingCount": 1,

        "componentData.missingValues": 1,
        "componentData.qualityPercentage": 1,
        "componentData.totalCount": 1,
        "componentData.missingCount": 1,

        "riskData.hasRiskProfile": 1,
        "riskData.missingBuildYears": 1,
        "riskData.missingConditions": 1,
        "riskData.invalidConditions": 1,
        "riskData.totalConditionsCount": 1,
        "riskData.totalBuildYearsCount": 1,
        "riskData.qualityPercentage": 1,
        "riskData.totalCount": 1,
        "riskData.missingCount": 1,

        "financeData.missingBuildYears": 1,
        "financeData.missingConditions": 1,
        "financeData.invalidConditions": 1,
        "financeData.missingPrices": 1,
        "financeData.invalidPrices": 1,
        "financeData.totalConditionsCount": 1,
        "financeData.totalBuildYearsCount": 1,
        "financeData.totalPricesCount": 1,
        "financeData.qualityPercentage": 1,
        "financeData.totalCount": 1,
        "financeData.missingCount": 1,

      },
    }

    const combined = merge(defaultFilter, queryParameters)
    const query = getUrlSearchParams(combined)

    const { data } = await useSamApi<PaginateResult<AssetDataQuality>>(`/asset-data-quality?${query}`)
    return data
  }

  const getOrganizationDataQuality = async (organizationId: string): Promise<OrganizationDataQuality> => {
    const { data } = await useSamApi<OrganizationDataQuality>(`/asset-data-quality/organization/${organizationId}`)
    return data
  }

  const updateAllOrganizationDataQuality = async (organizationId: string): Promise<void> => {
    await useSamApi<void>(`/asset-data-quality/organization/${organizationId}`, {
      method: `POST`,
    })
  }

  return {
    getAssetDataQualityByPage,
    getOrganizationDataQuality,
    updateAllOrganizationDataQuality,
  }
})
